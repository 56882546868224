<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { flip } from "svelte/animate";
  import { quintOut } from "svelte/easing";
  import Input from "$lib/admin/components/Input.svelte";
  import Button from "$lib/main/components/Button.svelte";
  import Icons from "$lib/main/components/Icons.svelte";

  const dispatch = createEventDispatcher();

  export let listItems: string[] = [];
  let hoveringItem: number | null = null;
  let dragCounter = 0;

  function addListItem() {
    listItems = [...listItems, ""];
  }

  function removeListItem(index: number) {
    listItems.splice(index, 1);
    listItems = [...listItems];
  }

  function handleDragStart(e: DragEvent, index: number) {
    e.dataTransfer?.setData("text/plain", index.toString());
  }
</script>

<div class="editing List">
  {#if listItems.length > 0}
    <div class="list-items draggable">
      {#each listItems as item, index (index)}
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <div
          animate:flip={{ duration: 200, easing: quintOut }}
          class={`flex list-item ${index + 1}`}
          class:hovering={hoveringItem === index}
          on:dragenter={() => {
            dragCounter++;
            hoveringItem = index;
          }}
          on:dragleave={() => {
            dragCounter--;
            if (dragCounter === 0) {
              hoveringItem = null;
            }
          }}
          on:dragover={(event) =>
            parseInt(event.dataTransfer?.getData("text/plain") || "") == index ? null : event.preventDefault()}
          on:drop={(event) => {
            event.preventDefault();
            listItems.splice(
              index,
              0,
              listItems.splice(parseInt(event.dataTransfer?.getData("text/plain") || ""), 1)[0],
            );
            listItems = listItems;
            dispatch("change", { bubbles: true });
          }}>
          <div
            class="grab"
            draggable="true"
            on:dragstart={(event) => handleDragStart(event, index)}
            on:dragend={() => (hoveringItem = null)}>
            <Icons name="grid" width="12px" height="12px" strokeWidth="1.5" />
          </div>
          <Input type="text" bind:value={item} on:keyup />
          <Button addClass="secondary" onlyIcon icon="trash-2" on:click={() => removeListItem(index)} />
        </div>
      {/each}
    </div>
  {/if}
  <Button addClass="primary" icon="plus-square" on:click={() => addListItem()}>Add item</Button>
</div>

<style>
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
