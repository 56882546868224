<script>
  import { page } from "$app/stores";
  import { project, postData } from "$lib/services/store";
  import Icons from "$lib/main/components/Icons.svelte";
  import { breadcrumbs } from "$lib/data/mappers/structured-data";

  export let path = "/";
  export let icon = null;
  export let hasDarkMode = false;

  const tournamentRoute = $project.settings.tournamentsUrl ?? "turneringar";

  // The code below relies on an empty "path" before the actual path,
  // to easily link to the home page. Let's comply with that while also
  // removing any invalid paths in the middle (like f.ex a.com//////article///test)
  $: splitPaths = ["", ...path.split("/").filter(Boolean)];

  const capitalizeFirst = (str) => str.at(0).toUpperCase() + str.slice(1);

  const cleanSegmentForTournaments = (segment) => {
    const match = segment.match(/^\d+-(.*)$/);
    return match ? match[1].replaceAll("-", " ") : segment.replaceAll("-", " ");
  };

  const cleanSegment = (segment, path) => {
    return path.startsWith(`/${tournamentRoute}`) ? cleanSegmentForTournaments(segment) : segment.replaceAll("-", " ");
  };
</script>

<svelte:head>
  {@html breadcrumbs($page.data.project, path, $postData)}
</svelte:head>

{#if path != "/"}
  <div class:dark-mode={hasDarkMode} class="block block-breadcrumbs style-a">
    <div class="container">
      <div class="breadcrumbs-wrapper">
        <ul class="breadcrumb-list">
          {#if path.startsWith("/admin")}
            {#each splitPaths as c, i}
              {#if i == 0 && splitPaths.length < 3}
                <li class="home">
                  <a href="/admin"
                    ><span>
                      <Icons name="home" class="icon" />
                    </span></a>
                </li>
              {:else if i == 0}
                <li>
                  <a href="/admin"
                    ><span>
                      <Icons name="home" class="icon" />
                    </span></a>
                </li>
              {:else if i == 1}
                <!-- TODO: pass to remove admin from appearing twice in breadcrumbs -->
              {:else if i == splitPaths.length - 1}
                <li class="current"><span>{cleanSegment(c, path)}</span></li>
              {:else}
                <li class="parent">
                  <a href={path.split(c)[0] + c}><span>{cleanSegment(c, path).toLowerCase()}</span></a>
                </li>
              {/if}
            {/each}
          {:else}
            {#each splitPaths as c, i}
              {#if i == 0}
                <li>
                  <a href="/">
                    <span>
                      {#if icon || $project.name == null}
                        <Icons name="home" class="icon" />
                      {:else}
                        {$project.name}
                        <!-- TODO: replace with 'main-keyword' or another value in site settings/SEO settings -->
                      {/if}
                    </span>
                  </a>
                </li>
              {:else if i == splitPaths.length - 1}
                <li class="current">
                  <p>
                    {$postData?.seo?.breadcrumb ?? capitalizeFirst(cleanSegment(c, path).toLowerCase())}
                  </p>
                </li>
              {:else if $postData.parent && $postData.parent.seo && $postData.parent.seo.breadcrumb}
                <li class="parent">
                  <a href={path.split(c)[0] + c}>
                    <span>{$postData.parent.seo.breadcrumb}</span>
                  </a>
                </li>
              {:else}
                <li class="parent">
                  <a href={path.split(c)[0] + c}>
                    <span>{cleanSegment(c, path)}</span>
                  </a>
                </li>
              {/if}
            {/each}
          {/if}
        </ul>
      </div>
    </div>
  </div>
{/if}

<style>
  .dark-mode .block.block-breadcrumbs.style-a {
    background-color: transparent;
    padding: 0;
  }
  .dark-mode .block-breadcrumbs .container {
    padding: 0;
  }
  .breadcrumbs-wrapper {
    display: grid;
  }
  .breadcrumb-list li.current {
    min-width: 0;
  }
  .breadcrumb-list li.current p {
    font-size: 0.9rem;
    font-weight: 600;
    margin: 0;
    overflow: hidden;
    color: white;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
</style>
