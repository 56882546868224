<script lang="ts">
  import type SunEditor from "suneditor/src/lib/core";
  import { onMount } from "svelte";
  import { afterUpdate } from 'svelte';

  export let text: string = '';
  let editor: SunEditor;
  const id = Math.random().toString(16);

  // Update the editor when we swap blocks as this doesn't happen
  // when two blocks of the same type are swapped.
  afterUpdate(() => {
    if (editor && editor.getContents(false) !== text) {
      editor.setContents(text);
    }
  })

  onMount(async () => {
    // These MUST be dynamically imported, otherwise the build will fail.
    const suneditor = (await import("suneditor")).default;
    const plugins = (await import("suneditor/src/plugins")).default;

    editor = suneditor.create(id, {
      plugins: plugins,
      buttonList: [
        ["bold", "italic", "underline", "strike"],
        ["link", "blockquote"],
        ["list", "formatBlock"],
        ["removeFormat", "showBlocks"],
        ["codeView"]
      ],
      linkNoPrefix: true
    });
    editor.setContents(text);

    // Listener event to update the preview window as soon as content is being written
    editor.onKeyUp = function (event, core) {
      text = event?.target?.innerHTML as string;
    };
  });
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<div class="editor-wrapper" on:keyup>
  <div {id} />
</div>

<style>
  @import "suneditor/dist/css/suneditor.min.css";
</style>