<script lang="ts">
  import Group from "../../Group.svelte";

  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  export let value: string = "";

  function handleBackgroundChange(event: Event) {
    const target = event.target as HTMLSelectElement;
    const bgColor = target.value;
    dispatch("change", { bgColor });
  }
</script>

<Group colspan="2" addClass="form-item">
  <p style="font-size: 14px;">Background color</p>
  <select style="width: 100%;" name="blockBackground" id="blockBackground" on:change={handleBackgroundChange}>
    <option value="transparent" selected={value === "transparent"}>Transparent</option>
    <option value="#000000" selected={value === "#000000"}>Black</option>
    <option value="#3F3F3F" selected={value === "#3F3F3F"}>Grey</option>
    <option
      value="linear-gradient(180deg, #121212 0%, #454545 50%, #121212 100%);"
      selected={value === "linear-gradient(180deg, #121212 0%, #454545 50%, #121212 100%);"}>Gradient</option>
  </select>
</Group>
