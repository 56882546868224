<script>
  import Group from "$lib/admin/components/Group.svelte";
  import SunEditorForm from "$lib/admin/components/content/SunEditorForm.svelte";
  import Block from "$lib/admin/components/blocks/helpers/Block.svelte";
  import { page } from "$app/stores";
  import { isTournamentsRoute } from "$lib/services/utils";
  import { project } from "$lib/services/store";
  import BlockBackgroundSelector from "../helpers/BlockBackgroundSelector.svelte";
  import { onMount } from "svelte";

  export let props = null;
  export let adminView = false;

  const tournamentRoute = $project.settings.tournamentsUrl ?? "turneringar";

  let hasParagraph = false;
  onMount(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(props.content, "text/html");
    hasParagraph = doc.querySelector("p") !== null;
  });
</script>

{#if adminView}
  <BlockBackgroundSelector bind:value={props.bgColor} on:change={(e) => (props.bgColor = e.detail.bgColor)} />
  <Group addClass="form-item">
    {#if props}
      <SunEditorForm bind:text={props.content} placeholder="Write some great content" on:keyup />
    {/if}
  </Group>
{:else if isTournamentsRoute($page.url.pathname, tournamentRoute)}
  {#if props && props.content}
    <div style="background: {props.bgColor};padding: {hasParagraph ? '24px 0' : '0'};" class="dark-mode">
      <div class="container tournament-page">
        {@html props.content}
      </div>
    </div>
  {:else}
    <p class="empty-content">No content written yet. Write some text to get started!</p>
  {/if}
{:else}
  <Block
    blockClass={"block-image" + (props.imageBlockType ? " " + props.imageBlockType : "")}
    gridColumn={"g-col-12 g-start-1 g-col-md-10 g-start-md-2"}
    {props}
  >
    {#if props && props.content}
      {@html props.content}
    {:else}
      <p class="empty-content">No content written yet. Write some text to get started!</p>
    {/if}
  </Block>
{/if}

<style>
  :global(.dark-mode p span[style]) {
    color: #fff !important;
    background: none !important;
  }

  :global(.tournament-page a) {
    text-decoration: underline;
    text-decoration-color: #cc3129 !important;
    text-underline-offset: 8px;
    text-decoration-thickness: 3px;
    color: white;

    &:hover {
      color: lightgray;
    }
  }
</style>
