<script lang="ts">
    import Icons from "$lib/main/components/Icons.svelte";
    import { onMount } from "svelte";
    import Translate from "./Translate.svelte";

    export let title: string = "";
    export let icon: string = "";

    let isOpen: boolean = false;
    let contentHeight: number = 0;
    let contentRef: HTMLElement | null = null;

    onMount(() => {
        if (contentRef) {
            contentHeight = contentRef.clientHeight;
        }
    });

    function toggleCollapse() {
        isOpen = !isOpen;
        contentHeight = isOpen ? contentRef?.scrollHeight || 0 : 0;
    }
</script>

<div class="collapse">
    <div role="presentation" class="header" on:click={toggleCollapse}>
        <div class="title-box">
            <div class="icon-content">
                <Icons name={icon} strokeWidth={icon === "rocket" ? "0.2" : ""}
                ></Icons>
            </div>
            <Translate text={title} />
        </div>
        <div class="icon-container">
            <Icons
                name={isOpen ? "chevron-up" : "chevron-down"}
                strokeWidth="3"
                width="22px"
                height="22px"
            ></Icons>
        </div>
    </div>
    <div
        class="content"
        style="height: {contentHeight}px;"
        bind:this={contentRef}
    >
        <slot></slot>
    </div>
</div>

<style>
    .collapse {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        padding: 8px, 12px, 8px, 8px;
    }

    .header {
        padding: 10px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 700;
        line-height: 20.8px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .content {
        overflow: hidden;
        transition: height 0.3s ease;
        font-size: 16px !important;
        font-weight: 500 !important;
        line-height: 20.8px !important;
    }

    .title-box {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .icon-content {
        display: flex;
        padding: 10px;
        background: #c24029;
        border-radius: 8px;
    }
</style>
